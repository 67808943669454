import React, { useState } from 'react';
import './Intership_register.css';
import Nav from '../Nav';
import Footer from '../Footer';

const Intership_register = () => {
  const [formValues, setFormValues] = useState({
    name: '',
    location: '',
    department: '',
    email: '',
    phone: '',
    url: '', // Added URL field
  });
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const validateField = (name, value) => {
    let error = '';

    if (name === 'name' || name === 'location' || name === 'department') {
      if (!value) {
        error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required.`;
      } else if (/[^a-zA-Z\s]/.test(value)) {
        error = `${name.charAt(0).toUpperCase() + name.slice(1)} cannot contain numbers or special symbols.`;
      }
    }

    if (name === 'email') {
      if (!value) {
        error = 'Email is required.';
      } else if (!/^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/.test(value)) {
        error = 'Invalid email format.';
      }
    }

    if (name === 'phone') {
      if (!value) {
        error = 'Phone number is required.';
      } else if (!/^\d+$/.test(value)) {
        error = 'Phone number must contain only digits.';
      }
    }

    if (name === 'url') { // Validate URL
      if (!value) {
        error = 'URL is required.';
      } else if (!/^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/.*)?$/i.test(value)) {
        error = 'Invalid URL format.';
      }
    }

    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    const fieldErrors = Object.keys(formValues).reduce((errors, key) => {
      const error = validateField(key, formValues[key]);
      if (error) errors[key] = error;
      return errors;
    }, {});

    if (Object.keys(fieldErrors).length > 0) {
      setFormErrors(fieldErrors);
      setIsLoading(false);
      return;
    }

    // Convert form data to x-www-form-urlencoded format
    const formData = new URLSearchParams();
    for (const key in formValues) {
      formData.append(key, formValues[key]);
    }

    try {
      const response = await fetch('https://pixlvfx.com/pixlbackend/internmail.php', {
        method: 'POST',
        body: formData.toString(),  // Send as URL-encoded string
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded', // Correct content type
        },
      });

      if (response.ok) {
        const data = await response.json();
        setSuccessMessage('Registration successful!');

        setFormValues({ // Reset form values to initial state
          name: '',
    location: '',
    department: '',
    email: '',
    phone: '',
    url: '',
        });
        setFormErrors({});
        setTimeout(() => {
          window.location.reload();
        }, 5000);

      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'Registration failed. Please try again.');
      }
    } catch (error) {
      setErrorMessage('Something went wrong. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Nav />
      <div className='Intership-register-container'>
        <div className='head'>
          <h1>Register</h1>
        </div>
        <div className='Intership-register'>
          <form onSubmit={handleSubmit}>
            <div className='input-filed'>
              <label htmlFor='name'>Name *</label>
              <input
                type='text'
                name='name'
                value={formValues.name}
                onChange={handleChange}
                placeholder='Enter name..'
              />
              {formErrors.name && <p className='error'>{formErrors.name}</p>}
            </div>
            <div className='input-filed'>
              <label htmlFor='location'>Location *</label>
              <input
                type='text'
                name='location'
                value={formValues.location}
                onChange={handleChange}
                placeholder='Enter location..'
              />
              {formErrors.location && <p className='error'>{formErrors.location}</p>}
            </div>
            <div className='input-filed'>
              <label htmlFor='department'>Department *</label>
              <input
                type='text'
                name='department'
                value={formValues.department}
                onChange={handleChange}
                placeholder='Enter department..'
              />
              {formErrors.department && <p className='error'>{formErrors.department}</p>}
            </div>
            <div className='input-filed'>
              <label htmlFor='email'>Email *</label>
              <input
                type='email'
                name='email'
                value={formValues.email}
                onChange={handleChange}
                placeholder='Enter email..'
              />
              {formErrors.email && <p className='error'>{formErrors.email}</p>}
            </div>
            <div className='input-filed'>
              <label htmlFor='phone'>Phone Number *</label>
              <input
                type='text'
                name='phone'
                value={formValues.phone}
                onChange={handleChange}
                placeholder='Enter phone number..'
              />
              {formErrors.phone && <p className='error'>{formErrors.phone}</p>}
            </div>
            <div className='input-filed'>

              <label htmlFor='url'>Portfolio/Website URL *</label>

              <label htmlFor='url'>Showreel/Youtube URL *</label>

              <input
                type='url'
                name='url'
                value={formValues.url}
                onChange={handleChange}
                placeholder='Enter a valid URL..'
              />
              {formErrors.url && <p className='error'>{formErrors.url}</p>}
            </div>

            {errorMessage && <p className='error'>{errorMessage}</p>}
            {successMessage && <p className='success'>{successMessage}</p>}

            <div className='input-filed'>
              <div className='btn'>
                <button type='submit' disabled={isLoading}>
                  {isLoading ? 'Wait...' : 'Submit'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Intership_register;
