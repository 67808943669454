import React, { useState } from "react";
import "../../Componets/job/jobregister.css";
import Nav from "../Nav";
import Footer from "../Footer";

const Jobregister = () => {
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contact: "",
    gender: "",
    experience: "",
    currentEmployer: "",
    noticePeriod: "",
    skillSet: "",
    url: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const validateField = (name, value) => {
    let error = "";

    // Validate fields for firstName, lastName, currentEmployer, and skillSet
    if (["firstName", "lastName", "currentEmployer", "skillSet"].includes(name)) {
      if (!value) {
        error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required.`;
      } else if (/[^a-zA-Z\s]/.test(value)) {
        error = `${name.charAt(0).toUpperCase() + name.slice(1)} cannot contain numbers or special symbols.`;
      }
    }

    // Validate email
    if (name === "email") {
      if (!value) {
        error = "Email is required.";
      } else if (!/^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/.test(value)) {
        error = "Invalid email format.";
      }
    }

    // Validate contact number
    if (name === "contact") {
      if (!value) {
        error = "Contact number is required.";
      } else if (!/^\d{10}$/.test(value)) {
        error = "Contact number must be exactly 10 digits.";
      }
    }

    // Validate gender
    if (name === "gender") {
      if (!value) {
        error = "Gender is required.";
      }
    }

    // Validate experience and noticePeriod
    if (["experience", "noticePeriod"].includes(name)) {
      if (!value) {
        error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required.`;
      } else if (isNaN(value) || value < 0) {
        error = `${name.charAt(0).toUpperCase() + name.slice(1)} must be a positive number.`;
      }
    }

    // Validate URL
    if (name === "url") {
      if (!value) {
        error = "URL is required.";
      } else if (!/^https?:\/\/(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]+/.test(value)) {
        error = "Invalid URL format.";
      }
    }

    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    const fieldErrors = Object.keys(formValues).reduce((errors, key) => {
      const error = validateField(key, formValues[key]);
      if (error) errors[key] = error;
      return errors;
    }, {});

    if (Object.keys(fieldErrors).length > 0) {
      setFormErrors(fieldErrors);
      setIsLoading(false);
      return;
    }

    try {
      // Convert form data to x-www-form-urlencoded format
      const formData = new URLSearchParams();
      for (const key in formValues) {
        formData.append(key, formValues[key]);
      }

      // Send the form data as URL-encoded
      const response = await fetch("https://pixlvfx.com/pixlbackend/jobmail.php", {
        method: "POST",
        body: formData.toString(), // Send as URL-encoded string
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",  // Ensure the correct content type is set
        },
      });

      if (response.ok) {
        const data = await response.json();

        setSuccessMessage("Registration successful!");  // Handle success

        setSuccessMessage("Registration successful!");  
        setFormValues({ // Reset form values to initial state
          firstName: "",
          lastName: "",
          email: "",
          contact: "",
          gender: "",
          experience: "",
          currentEmployer: "",
          noticePeriod: "",
          skillSet: "",
          url: "",
        });
        setFormErrors({});// Handle success
        setTimeout(() => {
          window.location.reload();
        }, 5000);

      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || "Registration failed. Please try again.");  // Handle error
      }
    } catch (error) {
      setErrorMessage("Something went wrong. Please try again later.");  // General error handling
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Nav />
      <div className="Intership-register-container">
        <div className="head">
          <h1>Job Registration</h1>
        </div>
        <div className="Intership-register">
          <form onSubmit={handleSubmit}>
            {/* Form fields for job registration */}
            <div className="input-filed">
              <label htmlFor="firstName">First Name *</label>
              <input
                type="text"
                name="firstName"
                value={formValues.firstName}
                onChange={handleChange}
                placeholder="Enter first name.."
              />
              {formErrors.firstName && <p className="error">{formErrors.firstName}</p>}
            </div>

            <div className="input-filed">
              <label htmlFor="lastName">Last Name *</label>
              <input
                type="text"
                name="lastName"
                value={formValues.lastName}
                onChange={handleChange}
                placeholder="Enter last name.."
              />
              {formErrors.lastName && <p className="error">{formErrors.lastName}</p>}
            </div>

            <div className="input-filed">
              <label htmlFor="email">Email *</label>
              <input
                type="email"
                name="email"
                value={formValues.email}
                onChange={handleChange}
                placeholder="Enter email.."
              />
              {formErrors.email && <p className="error">{formErrors.email}</p>}
            </div>

            <div className="input-filed">
              <label htmlFor="contact">Contact Number *</label>
              <input
                type="text"
                name="contact"
                value={formValues.contact}
                onChange={handleChange}
                placeholder="Enter contact number.."
              />
              {formErrors.contact && <p className="error">{formErrors.contact}</p>}
            </div>

            <div className="input-filed">
              <label htmlFor="gender">Gender *</label>
              <input
                type="text"
                name="gender"
                value={formValues.gender}
                onChange={handleChange}
                placeholder="Enter gender.."
              />
              {formErrors.gender && <p className="error">{formErrors.gender}</p>}
            </div>

            <div className="input-filed">
              <label htmlFor="experience">Experience (in years) *</label>
              <input
                type="text"
                name="experience"
                value={formValues.experience}
                onChange={handleChange}
                placeholder="Enter experience.."
              />
              {formErrors.experience && <p className="error">{formErrors.experience}</p>}
            </div>

            <div className="input-filed">
              <label htmlFor="currentEmployer">Current Employer *</label>
              <input
                type="text"
                name="currentEmployer"
                value={formValues.currentEmployer}
                onChange={handleChange}
                placeholder="Enter current employer.."
              />
              {formErrors.currentEmployer && <p className="error">{formErrors.currentEmployer}</p>}
            </div>

            <div className="input-filed">
              <label htmlFor="noticePeriod">Notice Period (in days) *</label>
              <input
                type="text"
                name="noticePeriod"
                value={formValues.noticePeriod}
                onChange={handleChange}
                placeholder="Enter notice period.."
              />
              {formErrors.noticePeriod && <p className="error">{formErrors.noticePeriod}</p>}
            </div>

            <div className="input-filed">
              <label htmlFor="skillSet">Skill Set *</label>
              <input
                type="text"
                name="skillSet"
                value={formValues.skillSet}
                onChange={handleChange}
                placeholder="Enter skill set.."
              />
              {formErrors.skillSet && <p className="error">{formErrors.skillSet}</p>}
            </div>

            <div className="input-filed">
              <label htmlFor="url">Portfolio/Website URL *</label>
              <input
                type="url"
                name="url"
                value={formValues.url}
                onChange={handleChange}
                placeholder="Enter portfolio URL.."
              />
              {formErrors.url && <p className="error">{formErrors.url}</p>}
            </div>

            {/* Display error or success message */}
            {errorMessage && <p className="error">{errorMessage}</p>}
            {successMessage && <p className="success">{successMessage}</p>}

            <div className="input-filed">
              <div className="btn">
                <button type="submit" disabled={isLoading}>
                  {isLoading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Jobregister;
