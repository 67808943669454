import React, { useState, useEffect } from "react";
import "../../css/TrustedOurClient.css";
import star_fill from "../../assets/star/start-fill.svg";
import star_off_fill from "../../assets/star/start-off-fill.svg";
import star_border from "../../assets/star/start-border.svg";

//common img
import User_Img from "../../assets/testimonials/user.jpg";
//img
import arvind from "../../assets/testimonials/arvind.jpg";
import sarika from "../../assets/testimonials/sarika.jpg";
import prathamesh from "../../assets/testimonials/prathamesh.jpg";
import snesga from "../../assets/testimonials/snesga.jpeg";
import Piyush from "../../assets/testimonials/Piyush.jpeg";
import keerti from "../../assets/testimonials/keerti.jpg";


import avinash from '../../assets/testimonials/IMG_9078.JPG'
import thalil from '../../assets/testimonials/IMG_20180518_162817.jpg'


const TrustedOurClient = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const feedBackInfo = [
    {

    

      img: avinash,

      name: "Avinash",
      occupation: "",
      message: `"Quality is more important than any other creature ", in  PIXL. I gain several learnings of new industry software with handling real projects. I am even more confident now and learning never ends so I learned a lot from the amazing mentors who guided me throughout by giving valuable suggestions. Gives me a platform where I could nourish my skills. Thank You...!`,
      rate: 4.5,
    },
    {
      img: prathamesh,
      name: "Partahmesh",
      occupation: "",
      message: `I started as an intern in pixl studio, during my last semester of the college and immediately felt welcomed through the great mentors. 
Upon graduating, I found myself in my first full-time opportunity working with an amazing company.
 Doing something that I enjoyed and progressing my carrier professionally and personally. 
Thank you for all the support that you have given.
`,
      rate: 5,
    },
    {
      img: Piyush,
      name: "Piyush",
      occupation: "",
      message: `During this Pandemic situation i always wanted to utilize this jobless situation happening to every artist this year. Luckily founded Pixl Studio fulfilling my desire to explore Houdini. From last year i was always planning to learn Houdini CHF but it was never possible. Today i feel so blessed to work as Intern in Pixl Studio. Also getting valuable feedbacks from working professionals adds up to my self growth everyday. Thank you Pixl studio for such an opportunity-Piyush Kumar, Bihar-India.`,
      rate: 4.5,
    },
    {
      img: sarika,
      name: "Sarika",
      occupation: "",
      message: `I am working as an intern in pixl studio, I feel so Happy that In this Pandemic Situation "PIXL" is giving INTERN Programme.With good Quality Production Pipline work ,handling real projects.Mentors are so good 
 More Patience to clearing doubts and giving valuable suggestions.`,
      rate: 5,
    },
    {
      img: snesga,
      name: "Snehsa",
      occupation: "",
      message: `Working with PIXL team has been fantastic from the very beginning.
The team processes great narrative skills and tells important details
 with sensitivity and wit. The team really Inspire us by their
perfection and hardwork. We immediately see the benefit to ourselves.
We appreciate their attention to details and creative approach to
bringing our works online.
Thank you.`,
      rate: 5,
    },
    {

    
      img: thalil,

      name: "Tahil",
      occupation: "",
      message: `Working with PIXL team has been fantastic from the very beginning.
The team processes great narrative skills and tells important details
 with sensitivity and wit. The team really Inspire us by their
perfection and hardwork. We immediately see the benefit to ourselves.
We appreciate their attention to details and creative approach to
bringing our works online.
Thank you.`,
      rate: 5,
    },
    {

    

      img:arvind,

      name: "Arvind Thangavel",
      occupation: "",
      message: `Making it as a concept artist in the entertainment industry had been my eventual goal.
I was both excited and speechless when Pixl Studios offered the opportunity and took me under their wing. Everyday so far has been a great learning opportunity. Thank you for making this dream possible, Team Pixl! `,
      rate: 5,
    },
    {

   

      img:keerti,

      name: "keerti raj",
      occupation: "",
      message: `Kartik has been an invaluable mentor, showcasing exemplary computer graphics expertise, impeccable professionalism, and genuine kindness. His dedication to staying updated on the latest technologies, coupled with his inspirational guidance, has significantly enhanced my skills. Kartik's humility, confidence, and client-centric approach earn him the utmost respect. I wholeheartedly endorse Kartik for any computer graphics endeavor, and I'm grateful for his mentorship. `,
      rate: 5,
    },

  ];

  // Function to get the star rating
  const getStarRating = (rating) => {
    const stars = [];
    for (let i = 0; i < 6; i++) {
      if (i < Math.floor(rating)) {
        stars.push(<img key={i} src={star_fill} alt="" />);
      } else if (i === Math.floor(rating) && rating % 1 !== 0) {
        stars.push(<img key={i} src={star_off_fill} alt="" />);
      } else {
        stars.push(<img key={i} src={star_border} alt="" />);
      }
    }
    return stars.slice(0, 5);
  };

  // Effect to change the current index every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % feedBackInfo.length);
    }, 2000); // Change every 5 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [feedBackInfo.length]);

  return (
    <div className="trustedourclient-container">
      <div className="trustedour">
        {/* <div className='left'>
          <h1>We are trusted by our Clients</h1>
        </div> */}
        <div className="right">
          {feedBackInfo.map((info, index) => (
            <div
              className={`list ${index === currentIndex ? "active" : ""}`}
              key={index}
              style={{ display: index === currentIndex ? "block" : "none" }}
            >
              <div className="profile-name">
                <div className="user-name">
                  <div className="left">
                    <div className="img">
                      <img src={info.img} alt={info.name} />
                    </div>
                  </div>
                  <div className="right">
                    <div className="user_name">{info.name}</div>
                    <div className="occupation">{info.occupation}</div>
                  </div>
                </div>
                <div className="rate">{getStarRating(info.rate)}</div>
              </div>
              <div className="message">
                <p>{info.message}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TrustedOurClient;
