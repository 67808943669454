import React from 'react'
import '../../css/Get_in_Touch.css'

const Get_in_Touch = () => {
  return (
    <div className='get-in-touch-container'>
        <div className='get-in-touch'>
            <h2>Get in Touch</h2>
            <p>Ready to explore our innovations or partner with us?</p>
            {/* <a href="mailto:connect@pixlvfx.com?subject=Innovation%20Inquiry&body=Dear%20PIXL%20Team,%0D%0A%0D%0AI%20am%20interested%20in%20exploring%20your%20innovations%20and%20potential%20partnership%20opportunities.%20Could%20you%20please%20provide%20me%20with%20more%20details%20about%20your%20projects"><button>DISCUSS YOUR PROJECT </button>
            </a> */}
            <p>
    
           <span>innovations@pixlvfx.com</span>
            </p>
        </div>
    </div>
  )
}

export default Get_in_Touch