import { Helmet } from 'react-helmet';
import React, { useEffect, useState } from 'react'
import ProductionTraining from '../Componets/Production_Training/ProductionTraining'
import Footer from '../Componets/Footer'
import Nav from '../Componets/Nav'
import Our_goal from '../Componets/Production_Training/Our_goal'

import Forum from '../Componets/internship/Forum';

import Courses from '../Componets/Production_Training/Courses';
import Howitwork from '../Componets/Production_Training/Howitwork';
import Whychoose from '../Componets/Production_Training/Whychoose';
import Whathappen from '../Componets/Production_Training/Whathappen';
import Readytojoin from '../Componets/Production_Training/Readytojoin';
import ScrollToTop from '../ScrollToTop';


const Production_Training_page = () => {
    // Store the meta description in state
    const [metaDescription, setMetaDescription] = useState("");

    // You can dynamically update the meta description if needed
    useEffect(() => {
      // You could call an API or some logic to update the description here
      setMetaDescription(
        "Kartik G - Visual Effects Lead with 15+ years of experience in FX, Liquid Simulations, and Pyro Techniques. Creator of stunning effects for Netflix, HBO, and major films. Discover the portfolio of an industry-leading VFX artist."
      );
    }, []); // This effect ru
  return (
    <>

    <ScrollToTop />

      <Helmet>
        
        <meta name="description" content={metaDescription} />
        <meta
          name="keywords"
          content="Visual Effects Studio, VFX Services, Film Visual Effects, Gaming Visual Effects, Real-Time VFX, Fluid Simulations, Particle Systems VFX, VR Visual Effects"
        />
      </Helmet>
      {/* ------------------------------------------------- */}
    <Nav/>
    <ProductionTraining/>
    <Our_goal/>

    <Forum />

    <Courses />
    <Whychoose />
    <Howitwork />
    <Readytojoin />
    <Whathappen />

    <Footer/>
    </>
  )
}

export default Production_Training_page