import React from 'react'
import '../../css/ProductionTraining.css'
import Production_slider_Img from '../../assets/production_Training/production_training_info.jpg'
import { useNavigate } from 'react-router-dom';






const ProductionTraining = () => {
  const navigate = useNavigate();

  return (
    <div className='ProductionTraining-container'>
      <div className='ProductionTraining'>
        <div className='top'>
          <img src={Production_slider_Img} alt=''/>
        </div>
        <div className='bottom'>
          <div className='head'>
            <h1>Exciting News from PIXL VFX! 🎬</h1>
           <div className='content'>
           <p>In collaboration with <strong>Drak, Wild VFX,</strong> and <strong>Indian VFX School</strong></p>
           <p>we’re thrilled to announce a groundbreaking series of online courses and workshops. Our courses cover essential topics in <strong>AI, Houdini, AR,</strong> and <strong>VR—all crafted to meet modern industry standards.</strong></p>
           </div>


           <button onClick={() => navigate('/Training-Register-pixlVFX')}>
            Apply Now
           </button>

          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductionTraining