import React, { useState, useEffect } from 'react';
import Nav from "../Componets/Nav";
import "../css/contact.css";
import layer_Img from '../assets/contact/motion_graphics lighting.png'
import Object_Img from '../assets/contact/robot_visual effects.png'
import { Helmet } from 'react-helmet';
import Contact_heading from '../Componets/contact_Page/Contact_heading';
import Lets_Talk_efforts from '../Componets/contact_Page/Lets_Talk_efforts';
import Inquire_Now from '../Componets/contact_Page/Inquire_Now';
import Address from '../Componets/contact_Page/Address';
import Footer from '../Componets/Footer';


import ScrollToTop from '../ScrollToTop';


const ContactUs = () => {
   // Store the meta description in state
   const [metaDescription, setMetaDescription] = useState("");

   // You can dynamically update the meta description if needed
   useEffect(() => {
     // You could call an API or some logic to update the description here
     setMetaDescription("Connect with PIXL VISUAL EFFECTS in Pondicherry for advanced VFX solutions, FX simulations, and visual effects consultation.");
   }, []); // This effect runs once when the component is mounted

// Handle Scroll Page

window.addEventListener('scroll', function() {
  const threshold = 1000; // The number of pixels from the bottom to trigger the message
  const scrollPosition = window.scrollY + window.innerHeight; // current scroll position + visible area
  const documentHeight = document.body.offsetHeight; // total document height

  // Check if user is within threshold of the bottom
  if (documentHeight - scrollPosition <= threshold) {
   document.querySelector('.contact-container .robot-container').classList.add('active-end-scroll')
  }else{
    document.querySelector('.contact-container .robot-container').classList.remove('active-end-scroll')
  }
});

  return (
    <>


    <ScrollToTop />

      {/* ---------------- SEO ------------------ */}
      <Helmet>
        
        <meta name="description" content={metaDescription} />
        <meta
          name="keywords"
          content="Visual Effects Studio, VFX Services, Film Visual Effects, Gaming Visual Effects, Real-Time VFX, Fluid Simulations, Particle Systems VFX, VR Visual Effects"
        />
      </Helmet>
      {/* ------------------------------------------------- */}
    <div className="contact-container">
 
      <Nav />
      {/* fixed robot design */}
      <div className="robot-container">
        <div className="robot">
          <div className="layer">
            <img src={layer_Img} alt="high-quality robot VFX for film" />
          </div>
          <div className="layer">
            <img src={Object_Img} alt="realistic robot visual effects for movie" />
          </div>
        </div>
      </div>
      {/* ----------------------------------------- */}
    <Contact_heading/>
    <Lets_Talk_efforts/>
    <Inquire_Now/>
    <Address/>
    <Footer/>
    </div>
  </>
  );
};

export default ContactUs;
