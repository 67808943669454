import React from 'react';
import '../../css/forum.css'

function Forum() {
  return (
    <>
      <div className='forum-container'>
        <div className='forum'>
          <p>
            Join the conversation and connect with fellow trainers on our forum
          </p>
          <a href="https://pixlvfx.com/forums/" target="_blank" rel="noopener noreferrer">
            <button>Click Here</button>
          </a>
        </div>
      </div>
    </>
  );
}

export default Forum;
