import { Helmet } from "react-helmet";
import React, { useEffect, useState } from "react";
import Nav from "../Componets/Nav";
import Footer from "../Componets/Footer";
import Joblanding from "../Componets/job/Joblanding";
import Joblast from "../Componets/job/Joblast";
import Forum from "../Componets/internship/Forum";

import ScrollToTop from "../ScrollToTop";


function Jobs() {
  // Store the meta description in state
  const [metaDescription, setMetaDescription] = useState("");

  // You can dynamically update the meta description if needed
  useEffect(() => {
    // You could call an API or some logic to update the description here
    setMetaDescription(
      "Kartik G - Visual Effects Lead with 15+ years of experience in FX, Liquid Simulations, and Pyro Techniques. Creator of stunning effects for Netflix, HBO, and major films. Discover the portfolio of an industry-leading VFX artist."
    );
  }, []); // This effect ru
  return (
    <>
    <ScrollToTop />

      <Helmet>
        <meta name="description" content={metaDescription} />
        <meta
          name="keywords"
          content="Visual Effects Studio, VFX Services, Film Visual Effects, Gaming Visual Effects, Real-Time VFX, Fluid Simulations, Particle Systems VFX, VR Visual Effects"
        />
      </Helmet>
      {/* ------------------------------------------------- */}
      <Nav />
      <Joblanding />
      <Joblast />

      <Forum />
      <Footer />
    </>
  );
}

export default Jobs;
